// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

$('#slick-car').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  fade: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  centerMode: false,
  autoplaySpeed: 6000,
  appendArrows: '#slick-car-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-large-prev"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-large-next"></span></button>',
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        autoplay: false,

      }
    },
  ]
});

$('#slick-testimonials').slick({
  slidesToShow: 2,
  slidesToScroll: 1,
  dots: false,
  fade: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  centerMode: true,
  autoplaySpeed: 6000,
  appendArrows: '#slick-testimonials-arrows',
  prevArrow: '<button class="arrow-prev mh-5"><span class="sprite arrow-small-prev"></span></button>',
  nextArrow: '<button class="arrow-prev mh-5"><span class="sprite arrow-small-next"></span></button>',
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      }
    },
  ]
});


$('#slick-logos').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  variableWidth: false,
  arrows: false,
  autoplaySpeed: 10,
  speed: 1000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      }
    },
  ]
});

setTimeout(function(){
  $('#slick-logos-wp').css('opacity', 1)
}, 1000);
